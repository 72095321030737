import React from "react"
import Layout from "../components/HeaderFooter/Layout"
import ContentHeader from "../components/Contact/ContentHeader"
import ContactFormSection from "../components/Contact/ContactFormSection"
import { Helmet } from "react-helmet"

export default function contact() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ripplz - Get in Touch</title>
        <meta
          name="description"
          content="Looking for developers to carry out the bulk of your software development? Get in touch with our team today to see how we can help at 40% the cost."
        />
      </Helmet>
      <ContentHeader />
      <ContactFormSection />
    </Layout>
  )
}
