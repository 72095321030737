import React, { useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

const ContentContainer = styled.div`
  margin-bottom: 42px;
`
const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
  }
`
const TextBox = styled.div`
  max-width: 400px;
  width: 100%;
  padding-top: 62px;
  margin-right: 3%;

  @media (max-width: 1023px) {
    padding: 0 12%;
    max-width: 100%;
    margin: 22px 0 0 0;
    width: 80%;
  }

  @media (max-width: 768px) {
    padding: 0;
    text-align: center;
  }

  .address {
    margin: 20px 0;

    p {
      font-size: 1.2em;
    }
    @media (max-width: 1023px) {
      display: none;
    }
  }
`
const ContactForm = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  form {
    max-width: 400px;
    width: 100%;
    padding: 24px 24px 24px;
    background: #f4f4f4;
    margin-top: -40px;
    display: flex;
    flex-flow: column wrap;
    border: 1px solid #cad1dd;
    border-radius: 5px;
    label {
      font-size: 0.8em;
    }

    @media (max-width: 1023px) {
      margin-top: 22px;
      max-width: 800px;
    }

    @media (max-width: 768px) {
      width: 380px;
    }
  }
  p {
    font-size: 1.5em;
    text-align: center;
    margin: 10px auto;
  }
  input,
  textarea {
    display: block;
    width: 100%;
    min-height: 40px;
    padding: 7px 16px 3px;
    border: 1px solid #a5c3d9;
    border-radius: 5px;
    resize: vertical;
    outline: none;
    color: var(--cool-black);
    font-size: 0.8em;
    margin-bottom: 5px;
  }
  textarea {
    min-height: 80px;
  }
  input:focus {
    border: 1px solid var(--bold-blue);
  }
  .form-submit {
    margin: 15px auto;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 1px #282828;
    padding: 8px 24px;
    width: 40%;
    text-align: center;
    background-color: var(--warm-orange);
    color: var(--white);
    font-size: 1em;
    cursor: pointer;
  }
  .form-submit:focus,
  .form-submit:hover {
    background-color: #ffba59;
  }
  .form-submit:focus {
    color: var(--bold-blue);
    box-shadow: inset 1px 1px 2px black;
  }
  .form-submit[disabled] {
    cursor: not-allowed;
  }
`
const Message = styled.div`
  width: 370px;
  p {
    font-size: 0.8em;
  }
  a {
    color: var(--bold-blue);
  }
  a:hover {
    color: var(--warm-orange);
  }
`

export default function ContactFormSection() {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    jobTitle: "",
    company: "",
    message: "",
  })

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...formState,
      }),
    })
      .then(() => navigate("/submission-success"))
      .catch(error => alert(error))
  }

  return (
    <ContentContainer>
      <ContentWrapper>
        <TextBox>
          <h1>Get in touch</h1>
          <p>
            Whether you're building a new product, testing an existing solution
            or simply looking to add a strong development resource into your
            business, get in touch with our team today and see just how valuable
            Gen Z talent can be! We are bridging the digital skills gap and
            developing the software talent of the future – be part of that
            journey!
          </p>
          <div className="address">
            <h2>Find us</h2>
            <p>
              Airedale House, <br />
              423 Kirkstall Road, <br />
              Leeds, LS4 2EW
            </p>
          </div>
        </TextBox>
        <ContactForm>
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/submission-success"
            onSubmit={handleSubmit}
          >
            <noscript>
              <p>This form won’t work with Javascript disabled</p>
            </noscript>
            <p>Please complete the form below</p>
            <input name="form-name" value="contact" type="hidden" />
            <label htmlFor="name">Full Name: </label>
            <input
              id="name"
              name="name"
              type="text"
              placeholder="Enter full name"
              onChange={handleChange}
              required
            />
            <label htmlFor="email">Email: </label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Enter your email"
              onChange={handleChange}
              required
            />
            <label htmlFor="job">Job Title: </label>
            <input
              id="job"
              name="jobTitle"
              type="text"
              placeholder="Enter job title"
              onChange={handleChange}
              required
            />
            <label htmlFor="company">Company: </label>
            <input
              id="company"
              name="company"
              type="text"
              placeholder="Enter company name"
              onChange={handleChange}
              required
            />
            <label htmlFor="message">Message: </label>
            <textarea
              id="message"
              name="message"
              type="text"
              onChange={handleChange}
              placeholder="Enter message here"
            />
            <button type="submit" className="form-submit">
              Submit
            </button>
          </form>
          <Message>
            <p>
              By submitting this form, you agree to the processing of your
              personal data by Ripplz as described in the{" "}
              <a href="/privacy">Privacy Policy</a>.
            </p>
          </Message>
        </ContactForm>
      </ContentWrapper>
    </ContentContainer>
  )
}
